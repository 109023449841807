import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Grid from '../components/grid/grid';
import GridItem from '../components/grid/item';

const NotFoundPage = () => (
  <Layout title="Thank You">
    <section className="thank-you-page">
      <Grid>
        <GridItem>
          <h2>NOT FOUND</h2>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          <Link to="/" className="c-button--primary">
            Go back to the homepage
          </Link>
        </GridItem>
      </Grid>
    </section>
  </Layout>
);

export default NotFoundPage;
